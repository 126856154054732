<template>
  <b-col>
    <b-card title="User Information" sub-title="Update basic user information">
      <b-form-group label="Full Name" label-class="font-weight-bold pt-0">
        <b-input v-model="user.full_name" @change="info_changed=true"></b-input>
      </b-form-group>
      <b-form-group label="Title" label-class="font-weight-bold pt-0">
        <b-input v-model="user.title" @change="info_changed=true"></b-input>
      </b-form-group>
      <b-form-group label="Email" label-class="font-weight-bold pt-0">
        <b-input v-model="user.email" @change="info_changed=true"></b-input>
      </b-form-group>
      <b-form-group label="Phone Number" label-class="font-weight-bold pt-0">
        <b-input v-model="user.phone" @change="info_changed=true"></b-input>
      </b-form-group>
      <b-form-group label="Update password" label-class="font-weight-bold pt-0">
        <b-input v-model="new_password" type="password" @change="info_changed=true"></b-input>
      </b-form-group>
      <b-form-group label="Repeat updated password" label-class="font-weight-bold pt-0">
        <b-input v-model="new_password_repeat" type="password" @change="info_changed=true"></b-input>
      </b-form-group>
      <div v-if="new_password.length < 6 && new_password.length > 0">Password must be at least 6 characters</div>
      <div v-if="new_password !== new_password_repeat">Passwords must match!</div>
      <b-button :disabled="!ok_to_submit" variant="primary" @click="update_user">Submit</b-button>
    </b-card>
    <b-card title="Tag Subscriptions" sub-title="You will receive email notifications only for submissions you've authored or with tags you've subscribed to.">
      <b-list-group>
        <b-list-group-item v-for="tag in subscriptions" :key="tag.id">
            <b-form-checkbox v-model="tag.subscribed" switch @change="subscriptions_changed=true">
              <Tag :id="tag.id" :description="tag.description" :color="tag.color"></Tag>
            </b-form-checkbox>
        </b-list-group-item>
      </b-list-group>
      <b-button :disabled="!subscriptions_changed" variant="primary" @click="push_subscriptions">Submit</b-button>
    </b-card>
  </b-col>

</template>

<script>
import http from "@/http-common";
import Tag from "./Tag"

export default {
  name: "UserProfile",
  props: [
    "current_user",
    "loggedIn",
    "tags",
  ],
  components: {
    Tag,
  },
  data() {
    return {
      user: null,
      info_changed: false,
      subscriptions: [],
      subscriptions_changed: false,
      new_password: "",
      new_password_repeat: "",
    }
  },
  computed: {
    ok_to_submit() {
      return (this.new_password === this.new_password_repeat) && this.info_changed;
    },
  },
  methods: {
    update_user() {
      let loader = this.$loading.show()
      let password = null;
      if (this.new_password.length > 0) {
        password = this.new_password
      }
      http.put(`/user/update/${this.user.username}`, {},
          {
            params: {
              full_name: this.user.full_name,
              title: this.user.title,
              email: this.user.email,
              phone: this.user.phone,
              password: password,
            }
          }
      ).then((resp) => {
        this.user = resp.data;
        this.new_password = "";
        this.new_password_repeat = "";
        this.info_changed = false;
        this.$emit('show-alert',{
            message: `User '${this.user.username}' updated successfully!`,
            type: 'success',
            });
      }).catch((err) => {
        http.std_error_handler(this, err);
      }).finally(()=> {
        loader.hide();
      })

    },
    push_subscriptions() {
      let loader = this.$loading.show()
      http.put('/tag/subscribe', {
        username: this.current_user.username,
        tags: this.subscriptions
      })
      .then((resp) => {
        this.subscriptions = resp.data;
        this.subscriptions_changed = false;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
      .finally(() => {
        loader.hide();
      })
    },
    pull_subscriptions() {
      return http.get('/tag/subscribe',{
        params: {username: this.current_user.username}
      })
      .then((resp) => {
        this.subscriptions = resp.data;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      });
    },
  },
  beforeMount() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    } else {
      let loader = this.$loading.show()

      let usr_prom = http.get('/user/me')
      .then((resp) => {
        this.user = resp.data;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
      let tags_prom = this.pull_subscriptions();

      Promise.all([usr_prom, tags_prom])
      .finally(() => {
        loader.hide();
      });
    }
  },
}
</script>

<style scoped>

</style>